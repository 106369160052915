const initialState = {
  allArticles: [],
  selectedArticles: []
};

const selectArticlesReducers = (state = initialState, action) => {
  switch (action.type) {
    case "allArticles": {
      const newState = {
        ...state,
        ...{ allArticles: action.payload }
      };
      return newState;
    }
    case "selectArticle": {
      const newallArticles = state.allArticles.filter(filterRoom => {
        if (filterRoom.id === action.payload.id) {
          return false;
        }
        return true;
      });

      const newState = {
        ...state,
        ...{
          allArticles: newallArticles,
          selectedArticles: [...state.selectedArticles, action.payload]
        }
      };
      return newState;
    }
    case "removeSelectArticle": {
      const newAllSelectedArticles = state.selectedArticles.filter(
        filterRoom => {
          if (filterRoom.id === action.payload.id) {
            return false;
          }
          return true;
        }
      );

      const newState = {
        ...state,
        ...{
          allArticles: [...state.allArticles, action.payload],
          selectedArticles: newAllSelectedArticles
        }
      };
      return newState;
    }
    case "nextArticle": {
      const newAllSelectedArticles = state.selectedArticles.filter(
        filterArticle => {
          if (filterArticle.id === action.payload) {
            return false;
          }
          return true;
        }
      );

      const newState = {
        ...state,
        ...{
          selectedArticles: newAllSelectedArticles
        }
      };
      return newState;
    }
    case "cleanArticles": {
      const newState = {
        ...state,
        ...{
          selectedArticles: []
        }
      };
      return newState;
    }
    case "cleanAllArticles": {
      const newState = {
        ...state,
        ...{
          allArticles: []
        }
      };
      return newState;
    }
    default:
      return state;
  }
};

export default selectArticlesReducers;
