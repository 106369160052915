import React from "react";
import "./index.css";
import "semantic-ui-css/semantic.min.css";
import { withRouter } from "react-router-dom";
import {
  Segment,
  Container,
  Header,
  Form,
  Radio,
  Button,
  Dropdown
} from "semantic-ui-react";
import { handleNextArticleClick } from "../store/actions/selectArticlesAction";
import { addUserArticle } from "../store/actions/userArticlesAction";
import { userArticlespostData } from "../store/actions/userArticlesAction";
import { connect } from "react-redux";
import { baseUrl } from "./../store/url";

const ArticleDetails = props => {
  const options = [
    { key: 0, text: "", value: undefined },
    { key: 1, text: "1", value: 1 },
    { key: 2, text: "2", value: 2 },
    { key: 3, text: "3", value: 3 },
    { key: 4, text: "4", value: 4 },
    { key: 5, text: "5", value: 5 },
    { key: 6, text: "6", value: 6 },
    { key: 7, text: "7", value: 7 },
    { key: 8, text: "8", value: 8 },
    { key: 9, text: "9", value: 9 },
    { key: 10, text: "10", value: 10 },
    { key: 11, text: "11", value: 11 },
    { key: 12, text: "12", value: 12 },
    { key: 13, text: "13", value: 13 },
    { key: 14, text: "14", value: 14 },
    { key: 15, text: "15", value: 15 }
  ];

  return (
    <div>
      <div className="Title">
        <Header as="h2" color="black">
          {props.title}
        </Header>
      </div>
      <div>
        <Container>
          <Segment className="used">
            <Form>
              <Form.Field>Wähle den Zustand deines Artikels:</Form.Field>
              <Form.Field>
                <Radio
                  label="Neu"
                  name="radioGroup"
                  condition="new"
                  checked={props.condition === "new"}
                  onChange={props.changeCondition}
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  label="Wie neu"
                  name="radioGroup"
                  condition="like_new"
                  checked={props.condition === "like_new"}
                  onChange={props.changeCondition}
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  label="Gut"
                  name="radioGroup"
                  condition="good"
                  checked={props.condition === "good"}
                  onChange={props.changeCondition}
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  label="Akzeptabel"
                  name="radioGroup"
                  condition="acceptable"
                  checked={props.condition === "acceptable"}
                  onChange={props.changeCondition}
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  label="Defekt"
                  name="radioGroup"
                  condition="broken"
                  checked={props.condition === "broken"}
                  onChange={props.changeCondition}
                />
              </Form.Field>
            </Form>
          </Segment>
          <Segment className="age">
            <div className="ageText">Wie alt ist dein Artikel ungefähr?</div>
            <Dropdown
              clearable
              options={options}
              selection
              onChange={(e, data) => props.changeAge(data.value)}
            />
          </Segment>
        </Container>
      </div>
    </div>
  );
};

const GoNextFunction = props => {
  if (props.token === null) {
    return <div />;
  } else if (props.selectedArticles.length <= 1) {
    return (
      <Button
        primary
        onClick={() => props.lastArticle(props.selectedArticles[0].id)}
      >
        ERGEBNIS ANZEIGEN
      </Button>
    );
  } else {
    return (
      <Button
        primary
        onClick={() => props.goNext(props.selectedArticles[1].id)}
      >
        WEITER
      </Button>
    );
  }
};

class ArticelDetailComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      condition: "",
      age: "",
      new_price: "",
      price_reduced: "",
      option: ""
    };
  }

  // Button function
  // ------------------------------------------------------------------------------------------------------------------------

  goNext = nextArticleId => {
    const currentArticleNr = this.props.match.params.articleId;
    const addArticleData = {
      currentArticleNr: currentArticleNr,
      price_reduced: this.state.price_reduced
    };

    this.props.dispatch(addUserArticle(addArticleData));
    this.props.dispatch(userArticlespostData());
    if (this.props.selectedArticles.length !== 0) {
      const title = this.props.selectedArticles[1].name;
      const price_new = this.props.selectedArticles[1].price_new;
      this.setState({
        title: title,
        price_new: price_new,
        price_reduced: price_new
      });
    }
    this.setState({
      condition: "",
      age: ""
    });
    this.props.history.push(`/articles/${nextArticleId}`);
    this.props.dispatch(handleNextArticleClick(nextArticleId));
  };

  lastArticle = () => {
    const currentArticleNr = this.props.match.params.articleId;

    const addArticleData = {
      currentArticleNr: currentArticleNr,
      price_reduced: this.state.price_reduced
    };

    this.props.dispatch(addUserArticle(addArticleData));
    this.props.dispatch(userArticlespostData());
    this.props.history.push(`/summary`);
  };

  // Reduce Price
  // ------------------------------------------------------------------------------------------------------------------------
  getReducedprice = async currentState => {
    const currentprice = this.state.price_new;

    const data = {
      price_new: currentprice,
      condition: currentState.condition,
      age: currentState.age
    };
    const body = JSON.stringify(data);

    const headers = new Headers({
      "Content-type": "application/json"
    });

    const config = {
      method: "PUT",
      headers,
      body
    };

    const response = await fetch(`${baseUrl}/reduced-price`, config);

    const resReducedPrice = await response.json();

    this.setState({ price_reduced: resReducedPrice.price_reduced });
  };

  // Select form Functions
  // ------------------------------------------------------------------------------------------------------------------------

  changeCondition = (e, { condition }) => {
    this.setState({ condition: condition });

    const currentState = {
      condition: condition
    };

    this.getReducedprice(currentState);
  };

  changeAge = age => {
    this.setState({ age: age, option: age });

    const currentState = {
      age: age
    };

    this.getReducedprice(currentState);
  };

  // React
  // ------------------------------------------------------------------------------------------------------------------------

  componentDidMount = async () => {
    if (this.props.selectedArticles.length !== 0) {
      const title = await this.props.selectedArticles[0].name;
      const price_new = await this.props.selectedArticles[0].price_new;
      this.setState({
        title: title,
        price_new: price_new,
        price_reduced: price_new
      });
    }
  };

  // Render
  // ------------------------------------------------------------------------------------------------------------------------

  render() {
    return (
      <div className="articleDetails">
        <div>
          <ArticleDetails
            token={this.props.token}
            selectedArticles={this.props.selectedArticles}
            changeCondition={this.changeCondition}
            condition={this.state.condition}
            option={this.state.option}
            changeAge={this.changeAge}
            title={this.state.title}
          />
        </div>
        <div className="Articles-Button">
          <GoNextFunction
            selectedArticles={this.props.selectedArticles}
            token={this.props.token}
            goNext={this.goNext}
            lastArticle={this.lastArticle}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.loginReducer.token,
    selectedArticles: state.selectArticlesReducer.selectedArticles,
    userArticles: state.userArticlesReducer.userArticles
  };
};

export default connect(mapStateToProps)(withRouter(ArticelDetailComponent));
