import React from "react";
import "./index.css";
import "semantic-ui-css/semantic.min.css";
import { Link, withRouter } from "react-router-dom";
import {
  Menu,
  Container,
  Segment,
  Divider,
  Card,
  Header,
  Button,
  Image,
  Icon
} from "semantic-ui-react";
import {
  handleRoomClick,
  handleSelectedRoomClick
} from "../store/actions/selectRoomsAction";
import { connect } from "react-redux";
import {
  getArticles,
  cleanArticles
} from "../store/actions/selectArticlesAction";
import { cleanRooms, getRooms } from "../store/actions/selectRoomsAction";

const RoomsFunction = props => {
  if (props.token === null) {
    return (
      <div>
        {" "}
        <Menu.Item login={props.login} name="registration">
          Du bist nicht angemeldet!
          <Link to="/login"> Jetzt anmelden</Link>
        </Menu.Item>{" "}
      </div>
    );
  } else if (props.selectedRooms.length === 0) {
    return (
      <Container>
        <Header as="h2" color="black">
          Welche Zimmer hast du in deinem Zuhause?
        </Header>
        <Segment className="Rooms-Segment">
          <div className="emptyState">
            <div className="Rooms-Image">
              <div className="Desktop">
                <Icon size="huge" color="blue" name="home" />
              </div>
              <div className="Mobile">
                <Icon size="big" color="blue" name="home" />
              </div>
            </div>
            <div className="Rooms-Text">
              <div>Wähle deine Zimmer aus der unteren Liste</div>
            </div>
          </div>
        </Segment>
        <Divider />
        <Segment className="Rooms-Segment">
          {props.allRooms.map(room => {
            return (
              <Card
                className="Rooms-Rooms"
                color="grey"
                key={room.id}
                onClick={() => props.dispatch.dispatch(handleRoomClick(room))}
              >
                <Card.Content className="cardContent">
                  <Card.Header>{room.name}</Card.Header>
                  <Icon size="big" color="black" name={room.iconName} />
                </Card.Content>
              </Card>
            );
          })}
        </Segment>
      </Container>
    );
  } else if (props.allRooms.length === 0) {
    return (
      <Container>
        <Header as="h2" color="black">
          Welche Zimmer hast du in deinem Zuhause?
        </Header>
        <Segment className="Rooms-Segment">
          {props.selectedRooms.map(room => {
            return (
              <Card
                className="Selected-Rooms"
                color="green"
                key={room.id}
                onClick={() =>
                  props.dispatch.dispatch(handleSelectedRoomClick(room))
                }
              >
                <Card.Content className="cardContent">
                  <Card.Header>{room.name}</Card.Header>
                  <Icon size="big" color="black" name={room.iconName} />
                </Card.Content>
              </Card>
            );
          })}
        </Segment>
      </Container>
    );
  } else
    return (
      <Container>
        <Header as="h2" color="black">
          Welche Zimmer hast du in deinem Zuhause?
        </Header>
        <Segment className="Rooms-Segment">
          {props.selectedRooms.map(room => {
            return (
              <Card
                className="Selected-Rooms"
                color="green"
                key={room.id}
                onClick={() =>
                  props.dispatch.dispatch(handleSelectedRoomClick(room))
                }
              >
                <Card.Content className="cardContent">
                  <Card.Header>{room.name}</Card.Header>
                  <Icon size="big" color="black" name={room.iconName} />
                </Card.Content>
              </Card>
            );
          })}
        </Segment>
        <Divider />
        <Segment className="Rooms-Segment">
          {props.allRooms.map(room => {
            return (
              <Card
                className="Rooms-Rooms"
                color="grey"
                key={room.id}
                onClick={() => props.dispatch.dispatch(handleRoomClick(room))}
              >
                <Card.Content className="cardContent">
                  <Card.Header>{room.name}</Card.Header>
                  <Icon size="big" color="black" name={room.iconName} />
                </Card.Content>
              </Card>
            );
          })}
        </Segment>
      </Container>
    );
};

const GotoSelectArticles = props => {
  if (props.token === null) {
    return <div />;
  } else if (props.selectedRooms.length === 0) {
    return <Button disabled>WEITER</Button>;
  } else {
    return (
      <Button
        primary
        onClick={() => props.changeRoom(props.selectedRooms[0].id)}
      >
        WEITER
      </Button>
    );
  }
};

class RoomsComponent extends React.Component {
  changeRoom = rommId => {
    this.props.history.push(`/rooms/${rommId}/articles`);
    this.props.dispatch(getArticles(rommId));
  };

  componentDidMount = () => {
    this.props.dispatch(cleanRooms());
    this.props.dispatch(cleanArticles());
    this.props.dispatch(getRooms());
  };

  render() {
    return (
      <div>
        <div className="rooms">
          <RoomsFunction
            token={this.props.token}
            allRooms={this.props.allRooms}
            selectedRooms={this.props.selectedRooms}
            dispatch={this.props}
          />
        </div>
        <div className="Rooms-Button">
          <GotoSelectArticles
            selectedRooms={this.props.selectedRooms}
            token={this.props.token}
            changeRoom={this.changeRoom}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.loginReducer.token,
    allRooms: state.selectRoomsReducer.allRooms,
    selectedRooms: state.selectRoomsReducer.selectedRooms
  };
};

export default connect(mapStateToProps)(withRouter(RoomsComponent));
