import React from "react";
import "./index.css";
import "semantic-ui-css/semantic.min.css";
import { withRouter } from "react-router-dom";
import { Icon, Header, Input, Button } from "semantic-ui-react";
import { loginData } from "../store/actions/loginAction";
import { connect } from "react-redux";
import { baseUrl } from "./../store/url";

const RegistrationBox = props => {
  return (
    <div>
      <div className="regContent">
        <Input
          className="regInput"
          type="text"
          placeholder="E-Mail-Adresse"
          value={props.email}
          onChange={props.EnterEmail}
        />
        <Input
          className="regInput"
          type="text"
          placeholder="Benutzername"
          value={props.username}
          onChange={props.EnterUsername}
        />
        <Input
          className="regInput"
          type="password"
          placeholder="Password"
          value={props.password}
          onChange={props.EnterPassword}
        />
        <Button primary onClick={props.SubmitRegistration}>
          KONTO ERSTELLEN
        </Button>
      </div>
      <div className="error">{props.error}</div>
    </div>
  );
};

class RegistrationComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      username: "",
      password: "",
      error: false
    };
  }

  EnterEmail = event => {
    const content = event.target.value;

    this.setState({ email: content });
  };

  EnterUsername = event => {
    const content = event.target.value;

    this.setState({ username: content });
  };

  EnterPassword = event => {
    const password = event.target.value;

    this.setState({ password: password });
  };

  SubmitRegistration = () => {
    const headers = new Headers({
      "Content-Type": "application/json"
    });

    const data = {
      email: this.state.email,
      password: this.state.password,
      username: this.state.username
    };

    const config = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data)
    };

    const promise = fetch(`${baseUrl}/signup`, config);
    promise
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          this.setState({ error: "Registrierung fehlgeschlagen" });
        }
      })
      // instead or additional to the localStorage we need to set the token in redux
      .then(user => {
        console.log("Regristration token", user.token);
        const token = localStorage.setItem("token", user.token);
        this.props.dispatch(loginData(user.token));
        return token;
      })
      .then(() => {
        this.props.history.push("/");
      })
      .catch(error => {
        console.log(error);
      });
  };

  render() {
    return (
      <div className="reg">
        <div className="regHeader">
          <Header as="h2">
            <Icon name="signup" />
            <Header.Content className="regText">
              Jetzt registrieren!
            </Header.Content>
          </Header>
        </div>
        <div>
          <div>
            <RegistrationBox
              email={this.state.email}
              EnterEmail={this.EnterEmail}
              username={this.state.username}
              EnterUsername={this.EnterUsername}
              password={this.state.password}
              EnterPassword={this.EnterPassword}
              SubmitRegistration={this.SubmitRegistration}
              error={this.state.error}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    error: state.loginReducer.error
  };
};

export default connect(mapStateToProps)(withRouter(RegistrationComponent));
