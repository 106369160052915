import React from "react";
import "./index.css";
// import { Menu } from "semantic-ui-react";
import { Link, withRouter } from "react-router-dom";

const FooterFunction = props => {
  return (
    <div className="Footer">
        <Link to="/about" className="Footer-Link">Über uns</Link>
      {/* <Menu>
        <Menu.Item>
          <Link to="/about">Über uns</Link>
        </Menu.Item>
      </Menu> */}
    </div>
  );
};


class FooterComp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      feeds: []
    };
  }

  render() {
    return (
      <div>
        <FooterFunction />
      </div>
    );
  }
}

export default withRouter(FooterComp);
