import { baseUrl } from "./../url";

export const addUserArticle = data => async (dispatch, getState) => {
  const postData = {
    articleId: data.currentArticleNr,
    price_reduced: data.price_reduced
  };

  dispatch({
    type: "addUserArticles",
    payload: postData
  });
};

export const userArticlespostData = () => async (dispatch, getState) => {
  const token = getState().loginReducer.token;
  const userArticles = getState().userArticlesReducer.userArticles;

  const headers = new Headers({
    "Content-Type": "application/json",
    token: token
  });

  const body = JSON.stringify(userArticles[0]);

  const config = {
    method: "POST",
    headers,
    body
  };

  const resposne = await fetch(`${baseUrl}/userArticles`, config);
  const userArticlesPosted = await resposne.json();

  console.log(userArticlesPosted);

  dispatch({
    type: "Posted"
  });
};
