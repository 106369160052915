const initialState = {
    token: null,
    error: null,
};

const loginReducers = (state = initialState, action) => {
    switch (action.type) {
        case "user_login": {
            const newState = {
                ...state,
                ...{ token: action.payload, error: null }
            }
            return newState;
        }
        case "error": {
            const newState = {
                ...state,
                ...{ token: null, error: action.payload }
            }
            return newState
        }
        case "logout": {
            const newState = {
                ...state,
                ...{ token: null, error: null }
            }
            localStorage.clear()
            return newState
        }
        default:
            return state
    }
}

export default loginReducers;