import React from "react";
import "./index.css";
import "semantic-ui-css/semantic.min.css";
import { Link, withRouter } from "react-router-dom";
import {
  Menu,
  Container,
  Segment,
  Divider,
  Card,
  Button,
  Header,
  Image,
  Icon
} from "semantic-ui-react";
import {
  handleArticlesClick,
  handleSelectedArticlesClick
} from "../store/actions/selectArticlesAction";
import { connect } from "react-redux";
import {
  cleanAllArticles,
  getArticles
} from "../store/actions/selectArticlesAction";
import { handleNextRoomClick } from "../store/actions/selectRoomsAction";

const RoomsFunction = props => {
  if (props.token === null) {
    return (
      <div>
        {" "}
        <Menu.Item login={props.login} name="registration">
          {" "}
          Du bist nicht angemeldet!
          <Link to="/login">Jetzt anmelden</Link>
        </Menu.Item>{" "}
      </div>
    );
  } else if (
    props.selectedArticles.filter(selectedArticle => {
      return selectedArticle.roomId == props.roomId;
    }).length === 0
  ) {
    return (
      <Container>
        <Header as="h2" color="black">
          Welche Artikel hast du in deinem {props.title}?
        </Header>
        <Segment className="Articles-Segment">
          <div className="emptyState">
            <div className="Article-Image">
              <Image src={require("../images/intro-desktop.png")} />
            </div>
            <div className="Article-Text">
              <div>Wähle deine Artikel aus der unteren Liste</div>
            </div>
          </div>
        </Segment>
        <Divider />
        <Segment className="Articles-Segment">
          {props.allArticles.map(article => {
            return (
              <Card
                className="Articles-Articles"
                color="grey"
                key={article.id}
                onClick={() =>
                  props.dispatch.dispatch(handleArticlesClick(article))
                }
              >
                <Card.Content>
                  <Card.Header>{article.name}</Card.Header>
                  <Card.Description>
                    Neuwert CHF {article.price_new}{" "}
                  </Card.Description>
                </Card.Content>
              </Card>
            );
          })}
        </Segment>
      </Container>
    );
  } else if (props.allArticles.length === 0) {
    return (
      <Container>
        <Header as="h2" color="black">
          Welche Artikel hast du in deinem {props.title}?
        </Header>
        <Segment className="Articles-Segment">
          {props.selectedArticles
            .filter(selectedArticle => {
              return selectedArticle.roomId == props.roomId;
            })
            .map(article => {
              return (
                <Card
                  className="Selected-Articles"
                  color="green"
                  key={article.id}
                  onClick={() =>
                    props.dispatch.dispatch(
                      handleSelectedArticlesClick(article)
                    )
                  }
                >
                  <Card.Content>
                    <Card.Header>{article.name}</Card.Header>
                    <Card.Description>
                      Neuwert CHF {article.price_new}{" "}
                    </Card.Description>
                  </Card.Content>
                </Card>
              );
            })}
        </Segment>
      </Container>
    );
  } else
    return (
      <Container>
        <Header as="h2" color="black">
          Welche Artikel hast du in deinem {props.title}?
        </Header>
        <Segment className="Articles-Segment">
          {props.selectedArticles
            .filter(selectedArticle => {
              return selectedArticle.roomId == props.roomId;
            })
            .map(article => {
              return (
                <Card
                  className="Selected-Articles"
                  color="green"
                  key={article.id}
                  onClick={() =>
                    props.dispatch.dispatch(
                      handleSelectedArticlesClick(article)
                    )
                  }
                >
                  <Card.Content>
                    <Card.Header>{article.name}</Card.Header>
                    <Card.Description>
                      Neuwert CHF {article.price_new}{" "}
                    </Card.Description>
                  </Card.Content>
                </Card>
              );
            })}
        </Segment>
        <Divider />
        <Segment className="Articles-Segment">
          {props.allArticles.map(article => {
            return (
              <Card
                className="Articles-Articles"
                color="grey"
                key={article.id}
                onClick={() =>
                  props.dispatch.dispatch(handleArticlesClick(article))
                }
              >
                <Card.Content>
                  <Card.Header>{article.name}</Card.Header>
                  <Card.Description>
                    Neuwert CHF: {article.price_new}{" "}
                  </Card.Description>
                </Card.Content>
              </Card>
            );
          })}
        </Segment>
      </Container>
    );
};

const GoNext = props => {
  if (props.token === null) {
    return <div />;
  } else if (
    props.selectedRooms.length === 1 &&
    props.selectedArticles.length === 0
  ) {
    return <Button disabled>WEITER</Button>;
  } else if (
    props.selectedRooms.length === 1 &&
    props.selectedArticles.length !== 0
  ) {
    return (
      <Button
        primary
        onClick={() => props.goDetailArticles(props.selectedRooms[0].id)}
      >
        WEITER
      </Button>
    );
  } else if (props.selectedArticles.length === 0) {
    return <Button disabled>WEITER</Button>;
  } else {
    return (
      <Button
        primary
        onClick={() => props.goNextRoom(props.selectedRooms[1].id)}
      >
        WEITER
      </Button>
    );
  }
};

class RoomsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: ""
    };
  }
  goNextRoom = roomId => {
    this.props.dispatch(cleanAllArticles());
    this.props.history.push(`/rooms/${roomId}/articles`);
    this.props.dispatch(getArticles(roomId));
    this.props.dispatch(handleNextRoomClick(roomId));
    this.setState({ title: this.props.selectedRooms[1].name });
  };

  goDetailArticles = () => {
    this.props.history.push(`/articles/${this.props.selectedArticles[0].id}`);
  };

  componentDidMount = async () => {
    if (this.props.selectedRooms.length !== 0) {
      const title = await this.props.selectedRooms[0].name;
      this.setState({ title: title });
    }
  };

  render() {
    return (
      <div>
        <div className="articles">
          <RoomsFunction
            token={this.props.token}
            allArticles={this.props.allArticles}
            selectedArticles={this.props.selectedArticles}
            dispatch={this.props}
            roomId={this.props.match.params.roomId}
            title={this.state.title}
          />
        </div>
        <div className="Articles-Button">
          <GoNext
            selectedArticles={this.props.selectedArticles}
            selectedRooms={this.props.selectedRooms}
            token={this.props.token}
            goNextRoom={this.goNextRoom}
            goDetailArticles={this.goDetailArticles}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.loginReducer.token,
    allArticles: state.selectArticlesReducer.allArticles,
    selectedArticles: state.selectArticlesReducer.selectedArticles,
    selectedRooms: state.selectRoomsReducer.selectedRooms
  };
};

export default connect(mapStateToProps)(withRouter(RoomsComponent));
