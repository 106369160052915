const initialState = {
  userArticles: []
};
const userArticlesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "addUserArticles": {
      const newState = {
        ...state,
        ...{ userArticles: [...state.userArticles, action.payload] }
      };
      return newState;
    }
    case "Posted": {
      const newState = {
        ...state,
        ...{
          userArticles: []
        }
      };
      return newState;
    }
    default:
      return state;
  }
};

export default userArticlesReducer;
