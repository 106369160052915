import React from "react";
import "./index.css";
import "semantic-ui-css/semantic.min.css";
import { Link, withRouter } from "react-router-dom";
import { Container, Segment, Button, Header, Image } from "semantic-ui-react";
import { getRooms } from "../store/actions/selectRoomsAction";
import { connect } from "react-redux";

const HomeFunction = props => {
  if (props.token === null) {
    return (
      <div>
        <div>
          <Container>
            <Segment className="Segment">
              <div className="Home-Header-Mobile">
                <Header as="h1">Sorge für deine Umwelt!</Header>
              </div>
              <div className="Desktop-Intro">
                <div className="Image-Intro">
                  <Image src={require("../images/living-room.png")} />
                </div>
              </div>
              <div className="Home-Box">
                <div className="ImageDektop">
                  <Image
                    className="Home-Sketch"
                    src={require("../images/intro-desktop.png")}
                  />
                </div>
                <div className="Home-Destkop-Box">
                  <div className="Home-Header-Desktop">
                    <Header as="h1">Sorge für deine Umwelt!</Header>
                  </div>
                  <div className="ImageMobile">
                    <Image
                      className="Home-Image"
                      src={require("../images/living-room-small.png")}
                    />
                  </div>
                  <div className="Home-Text">
                    In Schweizer Haushalten gibt es im Durchschnitt 255
                    unbenutzte Artikel mit einem geschätzen Wert von CHF 7'900. Diese Artikel
                    landen nach einer gewissen Zeit oft im Abfall,
                    obwohl sie für andere Personen noch von Wert sind. Endecke
                    jetzt, welchen Wert deine unbenutzten Artikel noch haben!
                  </div>
                  <div className="Home-CTAButton">
                    <Button primary>
                      <Link className="Link" to="/login">
                        JETZT LOSLEGEN
                      </Link>
                    </Button>
                  </div>
                </div>
              </div>
            </Segment>
          </Container>
        </div>
      </div>
    );
  } else
    return (
      <div>
        <div>
          <Container>
            <Segment className="Segment">
              <div className="Home-Header-Mobile">
                <Header as="h1">Sorge für deine Umwelt!</Header>
              </div>
              <div className="Desktop-Intro">
                <div className="Image-Intro">
                  <Image src={require("../images/living-room.png")} />
                </div>
              </div>
              <div className="Home-Box">
                <div className="ImageDektop">
                  <Image
                    className="Home-Sketch"
                    src={require("../images/intro-desktop.png")}
                  />
                </div>
                <div className="Home-Destkop-Box">
                  <div className="Home-Header-Desktop">
                    <Header as="h1">Sorge für deine Umwelt!</Header>
                  </div>
                  <div className="ImageMobile">
                    <Image
                      className="Home-Image"
                      src={require("../images/living-room-small.png")}
                    />
                  </div>
                  <div className="Home-Text">
                    In Schweizer Haushalten gibt es im Durchschnitt 255
                    unbenutzte Artikel mit einem geschätzen Wert von CHF 7'900. Diese Artikel
                    landen nach einer gewissen Zeit oft im Abfall,
                    obwohl sie für andere Personen noch von Wert sind. Endecke
                    jetzt, welchen Wert deine unbenutzten Artikel noch haben!
                  </div>
                  <div className="Home-CTAButton">
                    <Button primary>
                      <Link className="Link" to="/rooms">
                        JETZT LOSLEGEN
                      </Link>
                    </Button>
                  </div>
                </div>
              </div>
            </Segment>
          </Container>
        </div>
      </div>
    );
};

class HomeComponent extends React.Component {
  goRooms = () => {
    this.props.history.push(`/rooms`);
    this.props.dispatch(getRooms());
  };

  render() {
    return (
      <div>
        <HomeFunction token={this.props.token} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.loginReducer.token
  };
};

export default connect(mapStateToProps)(withRouter(HomeComponent));
