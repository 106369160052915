import { combineReducers } from "redux";
import loginReducer from "./loginReducer";
import selectRoomsReducer from "./selectRoomsReducer";
import selectArticlesReducer from "./selectArticlesReducer";
import userArticlesReducer from "./userArticlesReducer";

const reducers = combineReducers({
  loginReducer: loginReducer,
  selectRoomsReducer: selectRoomsReducer,
  selectArticlesReducer: selectArticlesReducer,
  userArticlesReducer: userArticlesReducer
});

export default reducers;
