import { baseUrl } from "./../url";

export const getArticles = id => async (dispatch, getState) => {
  const config = {
    method: "PUT"
  };

  const resposne = await fetch(`${baseUrl}/rooms/${id}`, config);
  const Articles = await resposne.json();
  dispatch({
    type: "allArticles",
    payload: Articles
  });
};

export const handleArticlesClick = room => async (dispatch, getState) => {
  dispatch({
    type: "selectArticle",
    payload: room
  });
};

export const handleSelectedArticlesClick = room => async (
  dispatch,
  getState
) => {
  dispatch({
    type: "removeSelectArticle",
    payload: room
  });
};

export const handleNextArticleClick = article => async (dispatch, getState) => {
  dispatch({
    type: "nextArticle",
    payload: article
  });
};

export const cleanArticles = room => async (dispatch, getState) => {
  dispatch({
    type: "cleanArticles",
    payload: room
  });
};

export const cleanAllArticles = room => async (dispatch, getState) => {
  dispatch({
    type: "cleanAllArticles",
    payload: room
  });
};
