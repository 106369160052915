import React from "react";
import "./index.css";
import "semantic-ui-css/semantic.min.css";
import { Link, withRouter } from "react-router-dom";
import { Icon, Header, Menu, Input, Button } from "semantic-ui-react";
import { login } from "../store/actions/loginAction";
import { connect } from "react-redux";

class LoginComp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emailOrUsername: "",
      password: ""
    };
  }
  HandleEmailUsernameChange = event => {
    const content = event.target.value;
    this.setState({ emailOrUsername: content });
  };

  HandlePasswordChange = event => {
    const password = event.target.value;

    this.setState({ password: password });
  };

  HandleSubmit = () => {
    this.props.dispatch(login(this.state)).then(data => {
      if (data) {
        this.props.history.push("/rooms");
      }
    });
  };

  render() {
    if (this.props.error) {
      return (
        <div className="log">
          <div className="loginTitel">
            <Header as="h2">
              <Icon name="signup" />
              <Header.Content className="logText">Anmelden</Header.Content>
            </Header>
          </div>
          <div className="loginArea">
            <div>
              <Input
                className="loginButton"
                placeholder="Benutzername oder E-Mail"
                type="text"
                value={this.state.emailOrUsername}
                onChange={this.HandleEmailUsernameChange}
              />
              <Input
                className="loginButton"
                error
                type="password"
                placeholder="Password"
                value={this.state.password}
                onChange={this.HandlePasswordChange}
              />
              <Button
                primary
                className="loginButton"
                onClick={this.HandleSubmit}
              >
                ANMELDEN
              </Button>
            </div>
            <div className="error">{this.props.error}</div>
          </div>
          <div className="Register">
            <Menu.Item login={this.props.login} name="registration">
              {" "}
              Noch kein Konto?
              <Link to="/registration"> Jetzt registrieren!</Link>
            </Menu.Item>
          </div>
        </div>
      );
    } else {
      return (
        <div className="log">
          <div className="loginTitel">
            <Header as="h2">
              <Icon name="signup" />
              <Header.Content className="logText">Anmelden</Header.Content>
            </Header>
          </div>
          <div className="loginArea">
            <div>
              <Input
                className="loginButton"
                type="text"
                placeholder="Benutzername oder E-Mail"
                value={this.state.emailOrUsername}
                onChange={this.HandleEmailUsernameChange}
              />
              <Input
                className="loginButton"
                type="password"
                placeholder="Passwort"
                value={this.state.password}
                onChange={this.HandlePasswordChange}
              />
              <Button
                primary
                className="loginButton"
                onClick={this.HandleSubmit}
              >
                ANMELDEN
              </Button>
            </div>
          </div>
          <div className="Register">
            <Menu.Item name="registration">
              {" "}
              Noch kein Konto?
              <Link to="/registration"> Jetzt registrieren!</Link>
            </Menu.Item>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    error: state.loginReducer.error
  };
};

export default connect(mapStateToProps)(withRouter(LoginComp));
