import React, { Component } from "react";
import "./App.css";
import HeaderComp from "../Header/";
import "semantic-ui-css/semantic.min.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import HomeComponent from "../Home";
import LoginComp from "../Login";
import FooterComp from "../Footer";
import RoomsComponent from "../SelectRooms";
import RegistrationComponent from "../Registration";
import SelectArticlesComponent from "../SelectArticles";
import SummaryComponent from "../Summary";
import ArticelDetailComponent from "../ArticleDetails";
import AboutComponent from "../About";
import { connect } from "react-redux";
import { loginData } from "../store/actions/loginAction";

// Home
// ------------------------------------------------------------------------------------------------------------------------

const Home = () => {
  return (
    <div>
      <div className="app">
        <HeaderComp />
      </div>
      <div className="body">
        <HomeComponent />
      </div>
      <div className="footer">
        <FooterComp />
      </div>
    </div>
  );
};

// Login and Regristration
// ------------------------------------------------------------------------------------------------------------------------

const Login = () => {
  return (
    <div>
      <div className="app">
        <HeaderComp />
      </div>
      <div className="body">
        <LoginComp />
      </div>
      <div className="footer">
        <FooterComp />
      </div>
    </div>
  );
};

const Registration = () => {
  return (
    <div>
      <div className="app">
        <HeaderComp />
      </div>
      <div className="body">
        <RegistrationComponent
        />
      </div>
      <div className="footer">
        <FooterComp />
      </div>
    </div>
  );
};

// Select Rooms and Articles
// ------------------------------------------------------------------------------------------------------------------------

const SelectRooms = () => (
  <div className="app">
    <HeaderComp />
    <div className="body">
      <RoomsComponent />
    </div>
    <div className="footer">
      <FooterComp />
    </div>
  </div>
);

const SelectArticles = () => (
  <div className="app">
    <HeaderComp />
    <div className="body">
      <SelectArticlesComponent />
    </div>
    <div className="footer">
      <FooterComp />
    </div>
  </div>
);

const ArticleDetails = () => (
  <div className="app">
    <HeaderComp />
    <div className="body">
      <ArticelDetailComponent />
    </div>
    <div className="footer">
      <FooterComp />
    </div>
  </div>
);

const Summary = () => (
  <div className="app">
    <HeaderComp />
    <div className="body">
      <SummaryComponent />
    </div>
    <div className="footer">
      <FooterComp />
    </div>
  </div>
);

const About = () => (
  <div className="app">
    <HeaderComp />
    <div className="body">
      <AboutComponent />
    </div>
    <div className="footer">
      <FooterComp />
    </div>
  </div>
);

// App class
// ------------------------------------------------------------------------------------------------------------------------

class App extends Component {
  // React Functions
  // ------------------------------------------------------------------------------------------------------------------------

  componentDidMount = async () => {
    this.props.dispatch(loginData(localStorage.getItem("token")));
  };

  // Render
  // ------------------------------------------------------------------------------------------------------------------------

  render() {
    return (
      <div className="App">
        <BrowserRouter>
          <Switch>
            <Route exact path="/" render={() => <Home />} />
            <Route exact path="/rooms" render={() => <SelectRooms />} />
            <Route
              exact
              path="/rooms/:roomId/articles"
              render={() => <SelectArticles />}
            />
            <Route
              exact
              path="/articles/:articleId"
              render={() => <ArticleDetails />}
            />
            <Route exact path="/summary" render={() => <Summary />} />
            <Route
              exact
              path="/registration"
              render={() => (
                <Registration
                />
              )}
            />
            <Route exact path="/login" render={() => <Login />} />
            <Route exact path="/about" render={() => <About />} />
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.loginReducer.token
  };
};

export default connect(mapStateToProps)(App);
