import React from "react";
import "./index.css";
import { Dropdown, Menu, Image } from "semantic-ui-react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../store/actions/loginAction";

const HeaderFunction = props => {
  if (props.token === null) {
    return (
      <div>
        <Menu color="black" className="headerTop">
          <div>
            <Image
              className="Header-Image"
              src={require("../images/ricardo-logo-small.png")}
            />
          </div>
          <div className="Header-Login">
            <Menu.Item name="home">
              <Link className="Header-Link" to="/">
                Home
              </Link>
            </Menu.Item>
            <Menu.Item name="myHome">
              <Link className="Header-Link" to="/login">
                Mein Zuhause
              </Link>
            </Menu.Item>
            <Menu.Item name="login">
              <Link className="Header-Link" to="/login">
                Anmelden
              </Link>
            </Menu.Item>
          </div>
        </Menu>
      </div>
    );
  } else {
    return (
      <div>
        <Menu color="black" className="headerTop">
          <div>
            <Image
              className="Header-Image"
              src={require("../images/ricardo-logo-small.png")}
            />
          </div>
          <div className="Header-Login">
            <Menu.Item name="home">
              <Link className="Header-Link" to="/">
                Home
              </Link>
            </Menu.Item>
            <Menu.Item name="myHome">
              <Link className="Header-Link" to="/summary">
                Mein Zuhause
              </Link>
            </Menu.Item>
            <Dropdown item text="Mein Konto">
              <Dropdown.Menu>
                <Dropdown.Item onClick={props.logOut}>Abmelden</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Menu>
      </div>
    );
  }
};

class HeaderComp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  userLogout = () => {
    this.props.dispatch(logout());
    this.props.history.push("/");
  };

  render() {
    return (
      <div>
        <HeaderFunction token={this.props.token} logOut={this.userLogout} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.loginReducer.token
  };
};

export default connect(mapStateToProps)(withRouter(HeaderComp));
