import { baseUrl } from "./../url";

export const loginData = token => {
  console.log("TokenLoginData,", token);

  return {
    type: "user_login",
    payload: token
  };
};

const errorLogin = data => {
  return {
    type: "error",
    payload: data
  };
};

export const logout = () => {
  return {
    type: "logout"
  };
};

export const login = state => dispatch => {
  const headers = new Headers({
    "Content-Type": "application/json"
  });

  var data = "";

  if (state.emailOrUsername.includes("@")) {
    data = {
      email: state.emailOrUsername,
      password: state.password
    };
  } else {
    data = {
      username: state.emailOrUsername,
      password: state.password
    };
  }

  const config = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(data)
  };

  return fetch(`${baseUrl}/login`, config)
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        dispatch(
          errorLogin(`Leider hat etwas nicht geklappt. Versuche es nochmals`)
        );
      }
    })
    .then(user => {
      localStorage.setItem("token", user.token);
      dispatch(loginData(user.token));
      return user;
    })
    .catch(error => {});
};
