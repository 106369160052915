import React from "react";
import "./index.css";
import "semantic-ui-css/semantic.min.css";
import { Link, withRouter } from "react-router-dom";
import {
  Menu,
  Container,
  Header,
  Table,
  Image,
  Segment,
  Card,
  Icon,
  Button,
  Divider
} from "semantic-ui-react";
import { connect } from "react-redux";
import { baseUrl } from "./../store/url";

const SummaryFunction = props => {
  console.log("theUSeraArticl", props.userArticles.length);
  if (props.token === null) {
    return (
      <div>
        {" "}
        <Menu.Item login={props.login} name="registration">
          {" "}
          Du bist nicht angemeldet!
          <Link to="/login">Jetzt anmelden</Link>
        </Menu.Item>{" "}
      </div>
    );
  } else if (
    props.userArticles.length === 0 ||
    props.userArticles.length === undefined
  ) {
    return (
      <div className="Emptystate">
        <div>
          <Image
            className="Summary-Image"
            src={require("../images/summary.png")}
          />
        </div>
        <div>
          <Header as="h2" color="black">
            Du hast noch kein Artikel selektiert.
          </Header>
          <div>Finde jetzt ungebrauchte Artikel in deinem Zuhause</div>
        </div>
        <div className="Button">
          <Button primary>
            <Link className="Link" to="/rooms">
              JETZT LOSLEGEN
            </Link>
          </Button>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div className="Desktop">
          <Container>
            <Header as="h2" color="black">
              Mein Zuhause
            </Header>
            <Image src={require("../images/living-room-summary.png")} />
            <Table celled striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Artikel</Table.HeaderCell>
                  <Table.HeaderCell>Zimmer</Table.HeaderCell>
                  <Table.HeaderCell>Wert</Table.HeaderCell>
                  <Table.HeaderCell>Was tun?</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {props.userArticles.map(article => {
                  return (
                    <Table.Row key={article.id}>
                      <Table.Cell>{article.article.name}</Table.Cell>
                      <Table.Cell>{article.article.room.name}</Table.Cell>
                      <Table.Cell>CHF {article.price_reduced}</Table.Cell>
                      {article.price_reduced > 20 ? (
                        <Table.Cell>
                          <a href="https://www.ricardo.ch/de/list">
                            Auf ricardo.ch verkaufen
                          </a>
                        </Table.Cell>
                      ) : (
                        <Table.Cell>
                          <a href="https://www.google.com/maps/search/%C3%B6kihof/@47.1835559,8.4936103,4981m/data=!3m1!1e3">
                            Umweltschonend entsorgen
                          </a>
                        </Table.Cell>
                      )}
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </Container>
        </div>
        <div className="Mobile">
          <Container>
            <Header as="h2" color="black">
              Mein Zuhause
            </Header>
            <Image src={require("../images/living-room-small.png")} />
            <Table celled striped>
              <Segment className="Articles-Segment">
                {props.userArticles.map(article => {
                  return (
                    <Card className="user-Articles" key={article.id}>
                      <Card.Content>
                        <Card.Header>{article.article.name}</Card.Header>
                        <Card.Meta>{article.article.room.name}</Card.Meta>
                        <Card.Description>
                          <a>
                            <Icon name="money" />
                            CHF {article.price_reduced}
                          </a>
                        </Card.Description>
                        <Divider />
                        {article.price_reduced > 20 ? (
                          <Card.Description>
                            <a href="https://www.ricardo.ch/de/list">
                              Auf ricardo.ch verkaufen
                            </a>
                          </Card.Description>
                        ) : (
                          <Card.Description>
                            <a href="https://www.google.com/maps/search/%C3%B6kihof/@47.1835559,8.4936103,4981m/data=!3m1!1e3">
                              Umweltschonend entsorgen
                            </a>
                          </Card.Description>
                        )}
                      </Card.Content>

                      <Card.Content extra />
                    </Card>
                  );
                })}
              </Segment>
            </Table>
          </Container>
        </div>
      </div>
    );
  }
};

class SummaryComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      userArticles: []
    };
  }

  fetchUserArticles = async () => {
    const token = this.props.token;
    console.log("Fetch token", token);
    const headers = new Headers({
      token: token
    });
    const config = {
      headers: headers
    };

    const response = await fetch(`${baseUrl}/userArticles`, config);
    const userArticlesArray = await response.json();
    return userArticlesArray;
  };

  async componentDidMount() {
    const userArticles = await this.fetchUserArticles();
    this.setState({ userArticles: userArticles });
  }
  render() {
    return (
      <div>
        <div className="summary">
          <SummaryFunction
            token={this.props.token}
            userArticles={this.state.userArticles}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.loginReducer.token
  };
};

export default connect(mapStateToProps)(withRouter(SummaryComponent));
