import { baseUrl } from "./../url";

export const getRooms = () => async (dispatch, getState) => {
  const token = getState().loginReducer.token;
  const headers = new Headers({
    "Content-Type": "application/json",
    token: token
  });
  const config = {
    headers: headers
  };
  const response = await fetch(`${baseUrl}/rooms`, config);
  const allRooms = await response.json();
  dispatch({
    type: "allRooms",
    payload: allRooms
  });
};

export const handleRoomClick = room => async (dispatch, getState) => {
  dispatch({
    type: "selectRoom",
    payload: room
  });
};

export const handleSelectedRoomClick = room => async (dispatch, getState) => {
  dispatch({
    type: "removeSelectRoom",
    payload: room
  });
};

export const handleNextRoomClick = room => async (dispatch, getState) => {
  dispatch({
    type: "nextRoom",
    payload: room
  });
};

export const cleanRooms = room => async (dispatch, getState) => {
  dispatch({
    type: "cleanRooms",
    payload: room
  });
};
