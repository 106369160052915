import React from "react";
import "./index.css";
import "semantic-ui-css/semantic.min.css";
import { Link, withRouter } from "react-router-dom";
import { Container, Segment, Button, Header, Image, Card, Icon } from "semantic-ui-react";
import { getRooms } from "../store/actions/selectRoomsAction";
import { connect } from "react-redux";

const AboutFunction = props => {
  if (props.token === null) {
    return (
      <div>
        <div>
          <Container>
            <Segment >
              <div className="About-Header">
                <Header as="h1">Über uns</Header>
              </div>
              <div className="Segment">
                <Card>
                  <Image src={require("../images/Daniel.png")} />
                  <Card.Content>
                    <Card.Header>Daniel</Card.Header>
                    <Card.Meta>
                      <span className='date'>Product Manager at ricardo.ch</span>
                    </Card.Meta>
                    <Card.Description></Card.Description>
                  </Card.Content>
                  <Card.Content extra>
                    <a>
                      <Icon name='user' />
                      5403 Instagram Followers
                    </a>
                  </Card.Content>
                </Card>
                <Card>
                  <Image src={require("../images/Rafael.png")} />
                  <Card.Content>
                    <Card.Header>Rafael</Card.Header>
                    <Card.Meta>
                      <span className='date'>Product Manager at ricardo.ch</span>
                    </Card.Meta>
                    <Card.Description></Card.Description>
                  </Card.Content>
                  <Card.Content extra>
                    <a>
                      <Icon name='user' />
                      12 Instagram Followers
                    </a>
                  </Card.Content>
                </Card>
              </div>
              <div className="About-MissionHeader">
                <Header as="h3">Unsere Mission</Header>
              </div>
              <div className="About-MissionText">
                    Wir wollen das Leben unserer Nutzer erleichtern und deren Probleme auf eine innovative Art und Weise lösen. 
              </div>
            </Segment>
          </Container>
        </div>
      </div>
    );
  } else
    return (
      <div>
        <div>
        <Container>
            <Segment >
              <div className="About-Header">
                <Header as="h1">Über uns</Header>
              </div>
              <div className="Segment">
                <Card>
                  <Image src={require("../images/Daniel.png")} />
                  <Card.Content>
                    <Card.Header>Daniel</Card.Header>
                    <Card.Meta>
                      <span className='date'>Product Manager at ricardo.ch</span>
                    </Card.Meta>
                    <Card.Description></Card.Description>
                  </Card.Content>
                  <Card.Content extra>
                    <a>
                      <Icon name='user' />
                      5403 Instagram Followers
                    </a>
                  </Card.Content>
                </Card>
                <Card>
                  <Image src={require("../images/Rafael.png")} />
                  <Card.Content>
                    <Card.Header>Rafael</Card.Header>
                    <Card.Meta>
                      <span className='date'>Product Manager at ricardo.ch</span>
                    </Card.Meta>
                    <Card.Description></Card.Description>
                  </Card.Content>
                  <Card.Content extra>
                    <a>
                      <Icon name='user' />
                      12 Instagram Followers
                    </a>
                  </Card.Content>
                </Card>
              </div>
              <div className="About-MissionHeader">
                <Header as="h3">Unsere Mission</Header>
              </div>
              <div className="About-MissionText">
                    Wir wollen das Leben unserer Nutzer erleichtern und deren Probleme auf eine innovative Art und Weise lösen. 
              </div>
            </Segment>
          </Container>
        </div>
      </div>
    );
};

class AboutComponent extends React.Component {
  goRooms = () => {
    this.props.history.push(`/rooms`);
    this.props.dispatch(getRooms());
  };

  render() {
    return (
      <div>
        <AboutFunction token={this.props.token} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.loginReducer.token
  };
};

export default connect(mapStateToProps)(withRouter(AboutComponent));
