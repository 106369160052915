const initialState = {
  allRooms: [],
  selectedRooms: []
};

const selectRoomsReducers = (state = initialState, action) => {
  switch (action.type) {
    case "allRooms": {
      const newState = {
        ...state,
        ...{ allRooms: action.payload }
      };
      return newState;
    }
    case "selectRoom": {
      const newAllRooms = state.allRooms.filter(filterRoom => {
        if (filterRoom.id === action.payload.id) {
          return false;
        }
        return true;
      });

      const newState = {
        ...state,
        ...{
          allRooms: newAllRooms,
          selectedRooms: [...state.selectedRooms, action.payload]
        }
      };
      return newState;
    }
    case "removeSelectRoom": {
      const newAllSelectedRooms = state.selectedRooms.filter(filterRoom => {
        if (filterRoom.id === action.payload.id) {
          return false;
        }
        return true;
      });

      const newState = {
        ...state,
        ...{
          allRooms: [...state.allRooms, action.payload],
          selectedRooms: newAllSelectedRooms
        }
      };
      return newState;
    }
    case "nextRoom": {
      const newAllSelectedRooms = state.selectedRooms.filter(filterRoom => {
        if (filterRoom.id === action.payload) {
          return false;
        }
        return true;
      });

      const newState = {
        ...state,
        ...{
          selectedRooms: newAllSelectedRooms
        }
      };
      return newState;
    }
    case "cleanRooms": {
      const newState = {
        ...state,
        ...{
          selectedRooms: []
        }
      };
      return newState;
    }
    default:
      return state;
  }
};

export default selectRoomsReducers;
